import styled from "styled-components"
import LogoSVG from "../../icons/logo-string-white.svg"

export const LogoOnMobile = styled.div`
	display: block;
	position: relative;
	top: -50px;
	background-image: ${"url(" + LogoSVG + ")"};
	background-size: 100%;
	background-repeat: no-repeat;
	width: 146px;
	height: 13px;
	z-index: 0;
	@media (min-height: 550px) {
		top: -70px;
	}
	@media (min-height: 750px) {
		top: -130px;
	}
	@media (min-width: 1000px) {
		display: none;
	}
`
