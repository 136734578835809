import React, { Component } from "react"
import HamburgerMenuIcon from "./HamburgerMenuIcon"
import styled from "styled-components"
import Nav from "./Navigation"
import { PoseGroup } from "react-pose"
import pose from "react-pose"
import { LogoOnMobile } from "./LogoOnMobile"
import { connect } from "react-redux"
import { changeIsDesktop, changeIsMobile, changeStickyMenu, toggleMenu } from "../../state/app"

const MenuThin = styled.div`
	position: fixed;
	cursor: pointer;
	z-index: 1001;
	background-color: ${({ theme }) => theme.colors.stringBlack};
	top: 0;
	right: 0;
	min-height: unset;
	height: 55px;
	width: 100%;
	transition: linear .3s;
	@media (min-width: 1000px) {
		height: 100vh;
		min-height: 759px;
		width: 70px;
		justify-content: flex-end;
	}
	@media (min-width: 1900px) {
		min-height: 820px;
	}
	@media (min-width: 2074px) {
		min-height: 870px;
	}
	
	&.sticky {
		@media (min-width: 1000px) {
			transform: translateX(0);
			width:35px;
			position: fixed;
			right: 0;
			top: 0;
		}
	}
`

const ThinLogoOnMobile = styled(LogoOnMobile)`
	top: 20px;
	left: 35px;
	opacity: 0;
	@media (min-height: 550px) {
		top: 20px;
	}
	@media (min-height: 750px) {
		top: 20px;
	}
	
	&.visible {
			opacity: 1;
	}
`

const MenuBlendPose = pose.div({
	open: {
		width: "100%",
		opacity: 0.5,
		transition: { opacity: { ease: "linear", duration: 400 }, width: { duration: 0 } },
	},
	close: {
		width: 0,
		opacity: 0,
		transition: { opacity: { ease: "linear", duration: 400 }, width: { delay: 400, duration: 0 } },
	},
})

const MenuBlend = styled(MenuBlendPose)`
	position: fixed;
	height: 100%;
	z-index:999;
	background-color:	${({ theme }) => theme.colors.stringBlack};
`

const PosedMenuWrapper = pose.div({
	enter: {
		x: 0,
		y: 0,
		delayChildren: 100,
		staggerChildren: 50,
		staggerDirection: 1,
		transition: {
			default: { duration: 300 },
		},
	},
	exit: {
		staggerDirection: -1,
		staggerChildren: 20,
		x: ({ isDesktop }) => isDesktop * 600 + "px",
		y: ({ isMobile }) => isMobile * -120 + "%",
		transition: {
			default: { delay: 200, duration: 400 },
		},
	},
})

const StyledMenuWrapper = styled(PosedMenuWrapper)`
  position: fixed !important;
  width: 100%;
  height: 100%;
	z-index: 1000;
	top: 0;
	right: 0;
	
	@media (min-width: 1000px) {
		width: unset;
	}
`

class Menu extends Component {

	_isMounted = false

	state = {
		isMobileScrolled: false,
	}

	constructor(props) {
		super(props)
		this.actionsOnScroll = this.actionsOnScroll.bind(this)
		this.checkDevice = this.checkDevice.bind(this)
	}

	checkIfMenuSticky() {
		const { dispatch } = this.props
		if (window.innerWidth > 1000 && window.scrollY > window.innerHeight) {
			dispatch(changeStickyMenu(true))
		} else {
			dispatch(changeStickyMenu(false))
		}
	}

	checkIfScrollOnMobile() {
		if (window.innerWidth <= 1000 && window.scrollY > 100) {
			this.setState({ isMobileScrolled: true })
		} else {
			this.setState({ isMobileScrolled: false })
		}
	}

	actionsOnScroll() {
		if (this._isMounted) {
			this.checkIfMenuSticky()
			this.checkIfScrollOnMobile()
		}
	}

	checkDevice() {
		const { dispatch } = this.props
		if (window.innerWidth < 1000) {
			dispatch(changeIsMobile(1))
			dispatch(changeIsDesktop(0))
		} else {
			dispatch(changeIsMobile(0))
			dispatch(changeIsDesktop(1))
		}
	}

	componentDidMount() {
		this._isMounted = true
		this.checkDevice()
		window.addEventListener("scroll", this.actionsOnScroll)
		window.addEventListener("resize", this.checkDevice)
	}

	componentWillUnmount() {
		this._isMounted = false
		window.removeEventListener("scroll", this.actionsOnScroll)
		window.removeEventListener("resize", this.checkDevice)
	}

	render() {
		const { isMenuVisible, isMenuSticky, isMobile, isDesktop, dispatch, menuData,activeSite } = this.props
		return (
			<>
				<MenuThin onClick={() => dispatch(toggleMenu(!isMenuVisible))} className={isMenuSticky ? "sticky" : ""}>
					<ThinLogoOnMobile className={(this.state.isMobileScrolled && !isMenuVisible) ? "visible" : ""}/>
					<HamburgerMenuIcon isSticky={isMenuSticky} isMenuVisible={isMenuVisible}/>
				</MenuThin>
				<MenuBlend onClick={() => dispatch(toggleMenu(!isMenuVisible))} pose={isMenuVisible ? "open" : "close"}/>
				<PoseGroup isMobile={isMobile} isDesktop={isDesktop}>
					{isMenuVisible &&
					<StyledMenuWrapper key="menu">
						<Nav activeSite={activeSite} id="menu" hideMenu={() => dispatch(toggleMenu(!isMenuVisible))} isSticky={isMenuSticky}
								 menuData={menuData}/>
					</StyledMenuWrapper>
					}
				</PoseGroup>
			</>
		)
	}
}

export default connect(state => ({
	isMenuVisible: state.app.isMenuVisible,
	isMenuSticky: state.app.isMenuSticky,
	isMobile: state.app.isMobile,
	isDesktop: state.app.isDesktop,
}), null)(Menu)
