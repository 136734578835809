export const theme = {
	colors: {
		stringBlue: "#293BDC",
		stringGrey: "#707070",
		stringLightBlue: "#A6B0B3",
		stringBlack: "#000",
		stringWhite: "#fff",
		background: "#D3D3D3",
		squareGray: "#D6D6D6",
		lightGray: "#e9e9e9",
		lightBlue: "#828FFF",
		stringLightGray: "#EFEFEF",
		lighterGrey: "#C7C7C7",
		calendarBlue: "#1F2DA7"
	},

	font: {
		thin: 100,

		light: 300,

		regular: 400,

		medium: 500,

		semiBold: 600,

		bold: 700,

		extraBold: 800,

		black: 900,

		textFont: "'Archia', sans-serif",
		headerFont: "'Archia', sans-serif",
	},

	lineHeight: {
		line1: "28px",
		line2: "23px",
		line3: "30px",
		line4: "40px",
		line5: "50px",
		line6: "45px",
		line7: "28px",
		line8: "17px",
		line9: "19px",
		line10: "13px",
		line11: "14px",
		line12: "46px",
		line13: "42px",
		line14: "24px",
		line15: "15px",
		line16: "20px",
		line17: "22px",
		line18: "34px", 
		line19: "96px",
		line20: "77px"
	},

	headers: {
		h1h: "183px", 
		h1: "142px",
		h1s: "130px",
		h2h: "105px",
		h2: "80px", 
		h3: "72px",
		h4: "60px",
		h5: "54px",
		h5s: "52px",
		h6: "40px",
		h6s: "30px",
		h7: "25px",
		h8: "24px",
		h9: "20px",
		h10h: "17px",
		h10: "16px",
		h11: "14px",
		h12: "12px",
		h13: "44px"
	},

	paragraphs: {
		p1: "20px",
		p2: "16px",
		p3: "14px",
		p4: "12px",
		p5: "11px",
		p6: "12px"
	},

	fontSizes: {
		f1: "142px",
		f2: "130px",
		f3: "105px",
		f4: "80px",
		f5: "72px",
		f6: "60px",
		f7: "54px",
		f8: "52px",
		f9: "40px",
		f10: "30px",
		f11: "25px",
		f12: "24px",
		f13: "20px",
		f14: "16px",
		f15: "14px",
		f16: "12px",
		f17: "10px",
		f18: "9px",
		f19: "8px",
		f20: "11px",
		f21: "126px",
		f22: "277px",
		f23: "22px",
		f24: "46px",
		f25: "18px",
		f26: "27px"
	}



}
