const initialState = {
	lang: 'pl',
	isMenuVisible: false,
	isMenuSticky: false,
	isLogoWhite: false,
	isMobile: 0,
	isDesktop: 1,
}

const TOGGLE_MENU = "TOGGLE_MENU"
const CHANGE_LANG = "CHANGE_LANG"
const CHANGE_STICKY_MENU = "CHANGE_STICKY_MENU"
const CHANGE_LOGO_COLOR = "CHANGE_LOGO_COLOR"
const CHANGE_IS_MOBILE = "CHANGE_IS_MOBILE"
const CHANGE_IS_DESKTOP = "CHANGE_IS_DESKTOP"

export const toggleMenu = isMenuVisible => ({
	type: TOGGLE_MENU, isMenuVisible,
})

export const changeLang = lang => ({
	type: CHANGE_LANG, lang,
})

export const changeStickyMenu = isMenuSticky => ({
	type: CHANGE_STICKY_MENU, isMenuSticky,
})

export const changeLogoColor = isLogoWhite => ({
	type: CHANGE_LOGO_COLOR, isLogoWhite,
})

export const changeIsMobile = isMobile => ({
	type: CHANGE_IS_MOBILE, isMobile,
})

export const changeIsDesktop = isDesktop => ({
	type: CHANGE_IS_DESKTOP, isDesktop,
})

export default (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_MENU:
			return { ...state, isMenuVisible: action.isMenuVisible }
		case CHANGE_LANG:
			return { ...state, lang: action.lang }
		case CHANGE_STICKY_MENU:
			return { ...state, isMenuSticky: action.isMenuSticky }
		case CHANGE_LOGO_COLOR:
			return { ...state, isLogoWhite: action.isLogoWhite }
		case CHANGE_IS_MOBILE:
			return { ...state, isMobile: action.isMobile }
		case CHANGE_IS_DESKTOP:
			return { ...state, isDesktop: action.isDesktop }
			
		default:
			return state
	}
};
