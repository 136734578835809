import React from "react"
import styled from "styled-components"
import pose from "react-pose"
import { LogoOnMobile } from "./LogoOnMobile"
import getSlug from "../../utils/getSlug"
import { Link } from "gatsby"
import { connect } from "react-redux"

const NavigationWrapper = styled.div`
	width: 100%;
	height: 100vh;
	background-color: ${({ theme }) => theme.colors.stringBlack};
	position: fixed;
	z-index: 1000;
	top: 0;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
 
	@media (min-width: 1000px) {
		right: 0;
		width: 510px;
		align-items: flex-start;
	}
	
	&.sticky {
		@media (min-width: 1000px) {
			position: fixed;
			top: 0
		}
	}
`

const NavigationList = styled.ul`
	color: ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.headers.h9};
	text-transform: uppercase;
	display: flex;
	flex-flow: column;
	 
	@media (min-width: 1000px) {
		padding: 0 0 0 160px;
	}
`

const NavigationListItemPosed = pose.li({
	enter: {
		x: 0, y: 0, opacity: 1,
	},
	exit: {
		x: 600,
		opacity: 0,
	},
})

const NavigationListItem = styled(NavigationListItemPosed)`
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
	padding: 0 0 21px 0;
	position: relative;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.headers.h9};
	font-weight: ${({ theme }) => theme.font.thin};
	text-decoration: none;

	&:hover, &:focus {
		font-weight: ${({ theme }) => theme.font.bold};
		outline: none;
		color: ${({ theme }) => theme.colors.stringBlue};
	}
`

const MenuLink = styled(Link)`

	-webkit-tap-highlight-color: transparent;
	padding: 0 0 21px 0;
	position: relative;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.headers.h9};
	font-weight: ${({ theme }) => theme.font.thin};
	text-decoration: none;

	&:hover, &:focus {
		font-weight: ${({ theme }) => theme.font.bold};
		outline: none;
		cursor: pointer;
		color: ${({ theme }) => theme.colors.stringBlue};
	}
`

const MenuItemNumber = styled.span`
	font-weight: ${({ theme }) => theme.font.bold};
	padding-right: 5px;
`

const NavigationSubmenuListPose = pose.ul({
	closed: {
		maxHeight: 0,
		paddingTop: 0,
		paddingBottom: 0,
	},
	opened: {
		paddingBottom: "30px",
		maxHeight: "300px",
	},
})

const NavigationSubmenuList = styled(NavigationSubmenuListPose)`
	color: ${({ theme }) => theme.colors.stringWhite};
	text-transform: uppercase;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	
	@media (min-width: 1000px){
		padding-left: 40px;
	}
`

const NavigationSubmenuListItem = styled.li`
	cursor: pointer;
	text-transform: lowercase;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.headers.h10};
	font-weight: ${({ theme }) => theme.font.regular};
	line-height: ${({ theme }) => theme.lineHeight.line6};

	&:hover {
		color: ${({ theme }) => theme.colors.stringBlue};
		cursor: pointer;
	}
	
	
	&:focus {
		outline: none;
	}
`

class Nav extends React.Component {

	state = {
		submenuVisible: false,
	}

	constructor(props) {
		super(props)
		this.escFunction = this.escFunction.bind(this)
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.props.hideMenu()
		}
	}

	toggleSubmenu() {
		this.setState({ submenuVisible: !this.state.submenuVisible })
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false)
	}

	render() {
		const { hideMenu, isSticky, isDesktop, isMobile, menuData, activeSite } = this.props
		const mainMenuData = []
		const submenuData = []

		menuData.forEach((item) => {
			if (item.parent !== null) {
				submenuData.push(item)
			} else {
				mainMenuData.push(item)
			}
		})

		const getSubmenuItems = (submenuItems) => {
			const subItems = []
			submenuItems.forEach((submenuItem => {
				{
					submenuData.forEach((item) => {
							if (item.title === submenuItem.title) {
								const submenuText = item.title
								const submenuItemSlug = item.wybierzPodstrone && item.wybierzPodstrone[0] && item.wybierzPodstrone[0].slug
								subItems.push({ text: submenuText, to: getSlug(submenuItemSlug, activeSite) })
							}
						},
					)
				}
			}))

			return subItems
		}

		const getMenuItems = (menuItems) => menuItems.map((menuItem) => {
			const slug = menuItem.wybierzPodstrone && menuItem.wybierzPodstrone[0] && menuItem.wybierzPodstrone[0].slug || ""

			return {
				text: menuItem.title,
				slug: slug,
				to: slug !== "" ? getSlug(slug, activeSite): "",
				submenu: menuItem.children.length !== 0 ? getSubmenuItems(menuItem.children) : [],
			}
		})

		const menuItems = getMenuItems(mainMenuData)

		return (
			<NavigationWrapper className={isSticky ? "sticky" : ""}>
				<LogoOnMobile/>
				<NavigationList>
					{menuItems.map((menuItem, key) =>
						<React.Fragment key={key}>
							{menuItem.slug !== "" && <NavigationListItem onClick={hideMenu}
																												 isDesktop={isDesktop}
																												 isMobile={isMobile}>
								<MenuLink to={`/${menuItem.to}`}> <MenuItemNumber>0{key + 1}</MenuItemNumber> {menuItem.text}</MenuLink>
							</NavigationListItem>}
							{menuItem.slug === "" && <NavigationListItem
								onClick={this.toggleSubmenu.bind(this)}><MenuItemNumber>0{key + 1}</MenuItemNumber> {menuItem.text}
							</NavigationListItem>}


							{menuItem.submenu.length !== 0 &&
							<NavigationSubmenuList pose={this.state.submenuVisible ? "opened" : "closed"}>
								{menuItem.submenu.map((submenuItem, key) => <NavigationSubmenuListItem key={key} as={Link}
																																											 to={`/${submenuItem.to}`}
																																											 onClick={hideMenu}>{submenuItem.text}</NavigationSubmenuListItem>)}
							</NavigationSubmenuList>
							}
						</React.Fragment>,
					)}
				</NavigationList>
			</NavigationWrapper>
		)
	}
}

export default connect(state => ({
	isMobile: state.app.isMobile,
	isDesktop: state.app.isDesktop,
}), null)(Nav)
