import React from "react"
import styled from "styled-components"

const HamburgerMenuIconWrapper = styled.div`
	position: absolute;
	top: 20px;
	right: 30px;
	width: 33px;
	cursor: pointer;
	height: 44px;
	transition: all .5s;
	@media (min-width: 1000px){
		top: 50%;
		left: 50%;
		transform:translate(-50%, -50%);
	}
	
	&.sticky {
		@media (min-width: 1000px) {
			transition: width .5s;
		}
	}
`

const BoxForIcon = styled.div`
	height: 20px;
	position: relative;
	transition: 0.5s;
	&.sticky {
			transform: scale(0.7);
	}
`

const IconText = styled.div`
	display: none;
	
	@media (min-width: 1000px) {
		display: flex;
		user-select: none;
		margin-top: 10px;
		letter-spacing:1px;
		margin-left: -2px;
		color: ${({ theme }) => theme.colors.stringWhite};
		font-size: ${({ theme }) => theme.fontSizes.f16};
		font-family: ${({ theme }) => theme.font.textFont}; 
			transition: 0.5s;
	}
	
	&.sticky {
		transform: scale(0.7);
		@media (min-width: 1000px) {
			margin-top: 0;
		}
	}
`

const Line = styled.div`
	height: 3px;
	position: absolute;
	background-color: ${({ theme }) => theme.colors.stringWhite};
	
	&:nth-child(1) {
		top: 0;
		width: 100%;
	}
	
	&:nth-child(2) {
		top: 13px;
		left: 0;
		width: 12%;
		transform: translateY(0);
		transition: transform .3s;
		
		&.animate {
			transform: translateX(31px);
			
			@media (min-width: 1000px) {
				transform: translateY(-27px);
				
				&.sticky {
					transform: translateY(-25px);
				} 
			}
		}
	}
	
	&:nth-child(3) {
		top: 13px;
		right: 0;
		width: 70%;
		transition: transform .3s;
		
		&.animate {
			transform: translateX(-43%);
		}
	}	
`

const HamburgerMenuIcon = ({ isSticky, isMenuVisible }) => (
	<>
		<HamburgerMenuIconWrapper className={isSticky ? "sticky" : ""}>
			<BoxForIcon className={(isSticky ? "sticky " : "")}>
				<Line className={(isSticky ? "sticky " : "") + (isMenuVisible ? "animate" : "")}/>
				<Line className={(isSticky ? "sticky " : "") + (isMenuVisible ? "animate" : "")}/>
				<Line className={(isSticky ? "sticky " : "") + (isMenuVisible ? "animate" : "")}/>
			</BoxForIcon>
			<IconText className={isSticky ? "sticky" : ""}>MENU</IconText>
		</HamburgerMenuIconWrapper>
	</>
)

export default HamburgerMenuIcon
